import React from 'react';
import { TodoContext } from '../context';
import '../styles/todoCounter.css';

function TodoCounter(){
    const { completedTodos, totalTodos } = React.useContext(TodoContext);
    return (
        <h2 className="TodoCounter">You have completed {completedTodos} of {totalTodos} tasks</h2>
    );
}

export {TodoCounter};