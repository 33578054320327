
import React from "react";
import { TodoProvider } from "../../context";
import { IsOnline } from "../../context/IsOnline";

import { AppUI } from './AppUI';
// -------------------- MAIN APP --------------------
function App() {
  // -------------------- RETURN APP INTERFACE --------------------
  return (
    <TodoProvider>
      {/* EL COMPONENTE IS ONLINE VERIFICA LA CONEXION A INTERNET PARA FUNCIONAR. EN ESTE CASO ESTÁ DESACTIVADA PARA AGREGARLA EN UN FUTURO DE SER NECESARIO */}
      {/* <IsOnline> */}
        <AppUI/>
      {/* </IsOnline> */}
    </TodoProvider>
  );
}

export default App;
