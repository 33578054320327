import React from 'react';
import { useLocalStorage } from './useLocalStorage';

const TodoContext = React.createContext();

function TodoProvider(props) {
  // ---------- USING THE CUSTOM HOOK TO SAVE THE LIST AND ASIGN DEFAULT VALUE ----------
  const {
    listItem: todoItems,
    saveItem: saveTodoItems,
    loading,
    error
  } = useLocalStorage( 'TASKS-TODOAPP_V1', []);

  // ---------- FUNCTIONALITY TO THE SEARCHBAR ----------
  const [searchValue, setSearchValue] = React.useState('');
  const totalTodos = todoItems.length ;
  const completedTodos = todoItems.filter( item => !!item.completed ).length ;
  let todolist = [];
  if(! searchValue.length >= 1){
    todolist = todoItems;
  }else{
    todolist = todoItems.filter( item => item.text.toLowerCase().includes(searchValue.toLowerCase()))
  }

  // ---------- GET VALUE FROM TEXTAREA IN ADD TASK MODAL ----------
  const [textareaValue, setTextareaValue] = React.useState('');

  // ---------- SHOW OR HIDE DELETE BUTTON ACCORDING IF EDITING IS ACTIVATED ----------
  const [editing, setEditing] = React.useState(false);
  const editingTasks = () => {
    const isEditing = editing;
    setEditing( !isEditing );
  }

  // ---------- SHOW OR HIDE ADD TASK MODAL ----------
  const [showModal, setShowModal] = React.useState(false);
  const addingTask = () => {
    setShowModal( !showModal );
  }

  // ---------- CHECK/UNCHECK THE TASK ACCORDING TO THE BUTTON PRESSED ----------
  const checkTask = (text, f) => {
    const taskIndex = todoItems.findIndex( item => (item.text === text));
    const newTodoList = [...todoItems];
    if(f === 1){
    newTodoList[taskIndex].completed = true;
    } else if (f === 0) {
      newTodoList[taskIndex].completed = false;
    }
    saveTodoItems(newTodoList);
  }

  // ---------- DELETE THE TASK ITEM WHEN '-' BUTTON IS PRESSED ----------
  const deleteTask = (text) => {
    const taskIndex = todoItems.findIndex( item => (item.text === text));
    const newTodoList = [...todoItems];
    newTodoList.splice(taskIndex,1);
    saveTodoItems(newTodoList);
  }

  // ---------- FUNCTIONALITY TO ADD A NEW TASK WHEN '+' BUTTON IS PRESSED ----------
  const addTask = () => {
    addingTask();
  }

  const acceptTask = () => {
    const newTask = document.getElementsByClassName('addTaskModal__input')[0].value;
    const taskIndex = todoItems.findIndex( item => (item.text === newTask));
    if (newTask && newTask.toString() !== '' && taskIndex === -1) {
      const newTodoList = [...todoItems];
      const newItem = {text: newTask, completed:false};
      newTodoList.push(newItem);
      saveTodoItems(newTodoList);
      setTextareaValue('');
    } else {
      return;
    }
  }


    return(
        <TodoContext.Provider value={{
            loading,
            error,
            completedTodos,
            totalTodos,
            searchValue,
            setSearchValue,
            textareaValue,
            setTextareaValue,
            todolist,
            checkTask,
            addTask,
            deleteTask,
            editingTasks,
            editing,
            acceptTask,
            addingTask,
            showModal,
        }}>
            {props.children}
        </TodoContext.Provider>
    );
}

export { TodoContext, TodoProvider };