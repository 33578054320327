import React from 'react';
import ReactDOM from 'react-dom';
import { TodoContext } from '../context';
import { TodoAcceptTask } from './TodoAcceptTask';
import { TodoCancelTask } from './TodoCancelTask';

import '../styles/addTaskModal.css';

function AddTaskModal() {
  const { showModal, textareaValue, setTextareaValue } = React.useContext( TodoContext );
  const onTextareaValue = (evt) => {
    setTextareaValue(evt.target.value);
  }

  React.useEffect( () => {
    document.getElementsByClassName('addTaskModal__input')[0].addEventListener(
      'keypress',
      function (evt) {
          if (!((evt.keyCode >= 97 && evt.keyCode <= 122) || (evt.keyCode >= 65 && evt.keyCode <= 90) || (evt.keyCode >= 48 && evt.keyCode <= 57) || evt.keyCode === 209 || evt.keyCode === 241 || evt.keyCode === 32)) {
              evt.preventDefault();
          }
      }
    )
  }
  , [])

  return ReactDOM.createPortal(
      <div className={`AddTaskModal-container ${!showModal && 'notShowing'}`}>
        <div className="AddTaskModal">
        
          <form className='addTaskForm'>
            <label>New Task:</label>
            <textarea
              className='addTaskModal__input'
              maxLength="60"
              placeholder='Learn to add new to do tasks'
              onChange={ onTextareaValue }
              value={textareaValue}></textarea>
          </form>

          <div className="buttons modal-buttons">
          <TodoCancelTask/>
          <TodoAcceptTask/>
          </div>
        </div>
      </div>,
      document.getElementById('AddTaskModalHTML')
  )
}

export { AddTaskModal } ;