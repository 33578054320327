import React from "react";

// ---------- CUSTOM HOOK TO USE LOCAL STORAGE (It stores it in the browser caché) ----------
function useLocalStorage(itemName, defaultValue){
    const [error, setError] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [listItem, setListItem] = React.useState(defaultValue);
   
    React.useEffect(() => {
      setTimeout( () => {
        try {
          const itemInLocalStorage = localStorage.getItem(itemName);
          let items;
          if (!itemInLocalStorage) {
            const firstValue = [
              {text: "Install to-do app", completed: true},
              { text: "Learn to use to-do app", completed: false }
            ];
            localStorage.setItem(itemName,JSON.stringify(firstValue));
            items = firstValue;
          } else {
            items = JSON.parse(itemInLocalStorage);
          }
  
          setListItem(items);
          setLoading(false);
        } catch (error) {
          setError(error);
        }
      },1000)
    }
    );
    const saveItem = (newTodoList) => {
      try {
        localStorage.setItem(itemName, JSON.stringify(newTodoList));
        setListItem(newTodoList);      
      } catch (error) {
        setError(error);
      }
  
    }
    return {
      listItem,
      saveItem,
      loading,
      error
    };
  } 
  
  export { useLocalStorage };