import React from 'react';
import { TodoContext } from '../context';

function TodoAcceptTask(){
    const { addingTask, acceptTask } = React.useContext( TodoContext );
    const onAcceptTask = () => {
        acceptTask()
        addingTask()
    };


    return(
        <button onClick={ onAcceptTask }>Accept</button>
    );
}

export {TodoAcceptTask};