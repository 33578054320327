import React from 'react';

import { TodoContext } from '../../context';
import { TodoCounter } from '../TodoCounter';
import { TodoSearchbar } from '../TodoSearchbar';
import { TodoList } from '../TodoList';
import { TodoListItem } from '../TodoListItem';
import { TodoAddButton } from '../TodoAddButton';
import { TodoEditButton } from '../TodoEditButton';
import { AddTaskModal } from '../AddTaskModal';

import '../../styles/App.css';

function AppUI() {

  const {
    error,
    loading,
    todolist,
    checkTask,
    deleteTask,
    editingTasks,
    addTask,
    totalTodos,
    editing
  } = React.useContext( TodoContext );

    return(
        <React.Fragment>
        <div className="primary-section">
          <TodoCounter/>
          <TodoSearchbar/>

            <TodoList>
            {loading && <p>Cargando</p>}
            {error && <p>Error</p>}
            {!(Number(loading || totalTodos) !== 0) && <p>Create you first task!</p>}
          
              {todolist.map(item => (
                <TodoListItem
                key={ item.text }
                text={ item.text }
                completed = { item.completed }
                onComplete = { () => checkTask(item.text, 1) }
                onUnComplete = { () => checkTask(item.text, 0) }
                onDelete = { () => deleteTask(item.text) }
                editing = { editing }
                />
              ))}
            </TodoList>
            </div>
            <div className="buttons">
              <TodoEditButton
              editingTasks = { () => editingTasks() }
              />
              <TodoAddButton
              addTask = {() => addTask()}
              />
        </div>
              <AddTaskModal/>
    </React.Fragment>
          
    );
}

export { AppUI };