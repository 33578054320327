import React from 'react';
import { TodoContext } from '../context';

function TodoCancelTask(  ){
    const { addingTask } = React.useContext(TodoContext);
    const cancelTask = () => {
        addingTask();
    };

    return(
        <button onClick={ cancelTask }>Cancel</button>
    );
}

export {TodoCancelTask};