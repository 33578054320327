import React from 'react';
import '../styles/list-items.css';

function TodoListItem (props){
    return(
        <li className={`ListItem ${props.completed && 'completed'}`}>
            <span className='item-description'>{props.text}</span>
            <span className='check-uncheck-buttons'>
                <button className={`check-uncheck-button ${props.editing && 'hidden'}`} onClick={props.onComplete}>✔</button>
                <button className={`check-uncheck-button ${props.editing && 'hidden'}`} onClick={props.onUnComplete}>✖</button>
                <button className={`delete-button ${!props.editing && 'hidden'}`} onClick={props.onDelete}>-</button>
            </span>
        </li>
    )
}

export { TodoListItem };