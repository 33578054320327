import React from 'react';
import { TodoContext } from '../context';
import '../styles/searchbar.css'

function TodoSearchbar(){

    const { searchValue, setSearchValue } = React.useContext(TodoContext);
    const onSearchValueChange = (evt) => {
        setSearchValue(evt.target.value);
    }

    return (
        <input
        className='TodoSearchbar'
        placeholder=" / Escribe para buscar..." 
        onChange={onSearchValueChange}
        value={searchValue}
        />
    );
}

export {TodoSearchbar};